import * as React from 'react'
import {Link} from 'gatsby'
import Layout from '../components/Layout'

// markup
const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <div className='page-container error'>
          <h1>Ups! Nie udało się znaleźć pożądanej strony.</h1>
          <p>404</p>
          <Link to='/'>
            <button>Wróć na stronę głowną</button>
          </Link>
        </div>
      </Layout>
    </>
  )
}

export default NotFoundPage
